<template>
  <div class="d-inline-flex flex-column pb-2 pr-2" :id='"id"+uuid'>
    <h6  v-html="labelHtml"></h6>
    <div v-if="image" class="d-inline-flex flex-wrap items-container">
      <div v-for="(choixn, key) in choix" :class="'form-check item-container '+classRadio" :key="choixn">
        <div class="select-container">
          <input class="form-check-input d-none" type="radio" :name="name" :id="choixn+uuid" :value="choixn" :checked="choixn == modelValue"
          @input="update($event.target.value)">
          <label class="form-check-label w-100" :for="choixn+uuid">
            <div >
              <img class="w-100" :src="image[key]" :alt="alt+choixn">
            </div>
          </label>
        </div>
      </div>
    </div>
    <div v-else class="d-inline-flex">
      <div v-for="choixn in choix" :class="'form-check '+classRadio" :key="choixn">
        <input class="form-check-input" type="radio" :name="name" :id="choixn+uuid" :value="choixn" :checked="choixn == modelValue"
        @input="update($event.target.value)">
        <label class="form-check-label w-100" :for="choixn+uuid">
          {{ choixn }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: { required: true },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true,
      default: " "
    },
    config: {
      type: Object,
      default: () => ({ type: "text" })
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: Number,
      default: 0
    },
    validation: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: "text"
    },
    validations: {
      type: Object,
      default: () => ({})
    },
    alt: { type: String, required: false, default : 'ruban' },
    choix: { type: Array, required: true },
    image: { type: Array, required: false },
    name: { type: String, required: true },
    classRadio: { type: String, required: false, default : '' },
  },
  mounted() {
      document.querySelector('#id'+this.uuid.toString()).parentNode.parentElement.classList.add("d-flex");
      document.querySelector('#id'+this.uuid.toString()).parentNode.parentElement.classList.add("flex-column");
  },
  computed:{
    labelHtml() {
      const requiredHtml =
        this.validations._exclusive && this.validations._exclusive.required
          ? ' <span class="label--required">*</span>'
          : "";
      return this.label + requiredHtml;
    }
  },
  methods: {
    update(value) {
      this.$emit("update:modelValue", value);
    }
  }
};
</script>
<style scoped>

  .items-container{
    max-height: 300px;
    overflow: scroll;
  }
  .item-container{
    padding: 5px;
  }
  .select-container{
    background-color: rgb(240, 240, 240);
    font-size: 12px;
    padding : 5px 15px 5px 15px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0);
  }
  .select-container:has(input:checked){
    border: 1px solid #858585;
  }
  .item-container label{
    margin:0;
  }
</style>
