import { createPinia } from 'pinia'
import App from "./App.vue";
import { createApp } from 'vue';
import FormTextArea from '@/components/FormTextarea.vue';
import FormText from '@/components/FormText.vue';
import FormAutocompleteInput from '@/components/FormAutocompleteInput.vue';
import FormDataList from '@/components/FormDataList.vue';
import FormSelect from '@/components/FormSelect.vue';
import FormWithPopin from '@/components/FormWithPopin.vue';
import FormRadio from '@/components/FormRadio.vue';
import FormNumber from '@/components/FormNumber.vue';
import FormHiddenConditionalValue from '@/components/FormHiddenConditionalValue.vue'
import { SchemaForm } from "formvuelate";


window.startVueComponent = function(node, initialData, scheme) {
  const pinia = createPinia();
  let v = createApp(App, {initialData, scheme});
  v.provide( 'initialData', initialData);
  v.component('FormTextArea', FormTextArea);
  v.component('FormDataList', FormDataList);
  v.component('FormAutocompleteInput', FormAutocompleteInput);
  v.component('FormText', FormText);
  v.component('FormWithPopin', FormWithPopin);
  v.component('SchemaForm', SchemaForm);
  v.component('FormSelect', FormSelect);
  v.component('FormRadio', FormRadio);
  v.component('FormNumber', FormNumber);
  v.component('FormHiddenConditionalValue', FormHiddenConditionalValue);
  v.use(pinia);
  v.mount(node);
  return v;
};

window.startVueComponent("#app", {
  update: "0",
  urls_preview:
    "/preview/testimage.php?type=za5n&langue=FR&texte=De%20la%20part%20%0ADu%20p%C3%A8re%20No%C3%ABl%20%0APour%20Paul%20&police=13&couleur=C54BE&logo=153;;;/preview/testimage.php?type=za5n&langue=FR&texte=JOYEUX%0ANO%C3%8BL%0APAUL&police=13&couleur=C54BE&logo=157",
  mail_rupture: "",
  pack_designation_rupture: "ZA5N",
  texte: {
    1: "De la part \r\nDu père Noël \r\nPour Paul ",
    2: "JOYEUX\r\nNOËL\r\nPAUL",
  },
  categorie_logo: "6",
  logo_id: ["12", "157"],
  couleur: ["C54BEEEE"],
  police_id: ["13", "13"],
  palette: "48",
  purchase: "",
});
