<template>
    <div class="flex-column pb-2 align-items-start">
        <h6 :for="uuid" v-html="labelHtml"/>
        <textarea
            :value="modelValue"
            :rows="rows"
            :cols="cols"
            class="p-2"
            @change="update($event.target.value)"
        ></textarea>
    </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      required: true,
      type: [String, Number]
    },
    required: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 3,
    },
    cols: {
      type: Number,
      default: 25,
    },
    label: {
      type: String,
      default: " "
    },
    config: {
      type: Object,
      default: () => ({ type: "text" }),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    uuid: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "text",
    },
  },
  computed: {
    labelHtml() {
      return this.label;
    },
  },
  methods: {
    update(value) {
      this.$emit("update:modelValue", value); 
    },
  },
};
</script>