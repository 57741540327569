<template>
  <div class="d-none">
    <datalist
      :value="modelValue"
      :required="required"
      :id="list_id"
      @input="update($event.target.value)"
    >
      <option
        v-for="option in options"
        :key="option"
        :value="option.id"
        :selected="option === modelValue"
      >
        {{ option.beautify }}
      </option>
    </datalist>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: { required: true },
    required: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {},
    },
    uuid: {
      type: Number,
      default: 0,
    },
    list_id: {
      type: String,
      default: "0",
    },
    options: { type: Array, required: true },
    disableNoSelection: { type: Boolean, default: false },
    validations: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    labelHtml() {
      const requiredHtml =
        this.validations._exclusive && this.validations._exclusive.required
          ? ' <span class="label--required">*</span>'
          : "";
      return this.label + requiredHtml;
    },
  },
  methods: {
    update(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>
