import { defineStore } from 'pinia';

export const useOptionsStore = defineStore('options', {
    state: () => ({ options: {} }),
    getters: {
        getOption(key){
            this.options[key]
        }
    },
    actions: {
        setOption(key, value){
            this.options[key] = value;
            return value;
        },
        fill(datas){
            this.options = datas;
        }
    }

});